<template>
  <div class="syntheticlist-style">
    <titles :title="$t('text.t363')" />
    <div class="types start-center">
      <div class="tlist start-center">
        <div
          class="titem"
          :class="type == 1 ? 'ac' : 'nos'"
          @click="changes(1)"
        >
          {{$t('text.t225')}}
        </div>
        <div
          class="titem"
          :class="type == 2 ? 'ac' : 'nos'"
          @click="changes(2)"
        >
          {{$t('text.t364')}}
        </div>
        <div
          class="titem"
          :class="type == 3 ? 'ac' : 'nos'"
          @click="changes(3)"
        >
          {{$t('text.t365')}}
        </div>
      </div>
    </div>
    <div class="types start-center addpadding" v-if="type == 3">
      <div class="tlist start-center">
        <div
          class="titem"
          :class="type2 == 1 ? 'ac' : 'nos'"
          @click="changes2(1)"
        >
          {{$t('text.t366')}}
        </div>
        <div
          class="titem"
          :class="type2 == 2 ? 'ac' : 'nos'"
          @click="changes2(2)"
        >
          {{$t('text.t367')}}
        </div>
      </div>
    </div>
    <div class="list" v-if="type == 1">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <div class="rows between-center">
            <div>{{$t('text.t368')}}:</div>
            <div>{{ $parseTime(item.createtime) }}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t369')}}:</div>
            <div>{{ item.get }}</div>
          </div>
          <div class="rows between-center">
            <div>
              True NFT#{{ item.tokenidOne
              }}<span v-if="item.tokenidList.length > 1">...</span>
            </div>
            <div @click="changeShow(item.tokenidList)">{{$t('text.t370')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t371')}}:</div>
            <div>{{ item.price }} USDT</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t428')}}:</div>
            <div>{{ item.destroy }} USDT</div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="list" v-else-if="type == 2">
      <van-list
        v-model="loading1"
        :finished="finished1"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist1"
      >
        <div class="item" v-for="item in list1" :key="item.id">
          <div class="rows between-center">
            <div>{{$t('text.t368')}}:</div>
            <div>{{ $parseTime(item.hc_time) }}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t369')}}:</div>
            <div>{{item.get}}</div>
          </div>
          <div class="rows between-center">
            <div>
              True NFT#{{ item.tokenidOne
              }}<span v-if="item.tokenidList.length > 1">...</span>
            </div>
            <div @click="changeShow(item.tokenidList)">{{$t('text.t370')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t373')}}:</div>
            <div>{{item.total}} OMT</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t374')}}:</div>
            <div>{{item.sy_amount}} OMT</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t375')}}{{item.flag}}{{$t('text.t376')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t377')}}:</div>
            <div>{{item.amount}} OMT</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t378')}}:</div>
            <div>{{ $parseTime(item.createtime) }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="list" v-else-if="type == 3 && type2 == 1">
      <van-list
        v-model="loading2"
        :finished="finished2"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist2"
      >
        <div class="item" v-for="item in list2" :key="item.id">
          <div class="rows2 rows between-center">
            <div>{{$t('text.t379')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t380')}}:</div>
            <div>{{item.ly_address}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t368')}}:</div>
            <div>{{ $parseTime(item.hc_time) }}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t369')}}:</div>
            <div>{{item.get}}</div>
          </div>
          <div class="rows between-center">
            <div>
              True NFT#{{ item.tokenidOne
              }}<span v-if="item.tokenidList.length > 1">...</span>
            </div>
            <div @click="changeShow(item.tokenidList)">{{$t('text.t370')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t366')}}:</div>
            <div>{{item.amount}} OMT</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t381')}}:</div>
            <div>{{ $parseTime(item.createtime) }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="list" v-else-if="type == 3 && type2 == 2">
      <van-list
        v-model="loading3"
        :finished="finished3"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist3"
      >
        <div class="item" v-for="item in list3" :key="item.id">
          <div class="rows2 rows between-center">
            <div>{{$t('text.t382')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t380')}}:</div>
            <div>{{item.ly_address}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t368')}}:</div>
            <div>{{ $parseTime(item.hc_time) }}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t369')}}:</div>
            <div>{{item.get}}</div>
          </div>
          <div class="rows between-center">
            <div>
              True NFT#{{ item.tokenidOne
              }}<span v-if="item.tokenidList.length > 1">...</span>
            </div>
            <div @click="changeShow(item.tokenidList)">{{$t('text.t370')}}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t367')}}:</div>
            <div>{{item.total}} OMP</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t383')}}:</div>
            <div>{{item.sy_amount}} OMP</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t384')}}:</div>
            <div>{{item.amount}} OMP</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t378')}}:</div>
            <div>{{ $parseTime(item.createtime) }}</div>
          </div>
          <div class="rows between-center">
            <div>{{$t('text.t375')}}{{item.flag}}{{$t('text.t376')}}</div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup round v-model="show">
      <div class="ppbox">
        <div class="ppbox-t1">{{ $t("text.t221") }}</div>
        <div class="ppbox-b1 between-center">
          <div class="ppbox-b1-item" v-for="(t, i) in idlist" :key="i">
            True NFT#{{ t }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      show: false,
      idlist: [],
      limit: 20,
      type: 1,
      type2: 1,

      list: [],
      loading: false,
      finished: false,
      page: 1,

      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,

      list2: [],
      loading2: false,
      finished2: false,
      page2: 1,

      list3: [],
      loading3: false,
      finished3: false,
      page3: 1,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changeShow(tokenidList) {
      console.log(tokenidList);
      this.idlist = tokenidList;
      this.show = true;
    },
    changes(e) {
      this.type = e;
      // if (this.type == 1 && !this.list.length) {
      //   this.getlist();
      // } else if (this.type == 2 && !this.list1.length) {
      //   this.getlist1();
      // }
      //  else if (this.type == 3 && this.type2 == 1 && !this.list2.length) {
      //   this.getlist2();
      // } else if (this.type == 3 && this.type2 == 2 && !this.list3.length) {
      //   this.getlist3();
      // }
    },
    changes2(e) {
      this.type2 = e;
      // if (this.type2 == 1 && !this.list2.length) {
      //   this.getlist2();
      // } else if (this.type2 == 2 && !this.list3.length) {
      //   this.getlist3();
      // }
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
      };
      this.$http.get("/destroy/destroyLog", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            data[i].tokenidList = data[i].tokenid.split(",");
            data[i].tokenidOne = data[i].tokenid.split(",")[0];
          }
        }
        this.list = [...this.list, ...data];
      });
    },
    getlist1() {
      let arg = {
        page: this.page1,
        limit: this.limit,
      };
      this.$http.get("/destroy/synthetic", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            data[i].tokenidList = data[i].tokenid.split(",");
            data[i].tokenidOne = data[i].tokenid.split(",")[0];
          }
        }
        this.list1 = [...this.list1, ...data];
      });
    },
    getlist2() {
      let arg = {
        page: this.page2,
        limit: this.limit,
      };
      this.$http.get("/destroy/realtime", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished2 = true;
        this.page2++;
        this.loading2 = false;
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            data[i].tokenidList = data[i].tokenid.split(",");
            data[i].tokenidOne = data[i].tokenid.split(",")[0];
          }
        }
        this.list2 = [...this.list2, ...data];
      });
    },
    getlist3() {
      let arg = {
        page: this.page3,
        limit: this.limit,
      };
      this.$http.get("/destroy/release", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished3 = true;
        this.page3++;
        this.loading3 = false;
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            data[i].tokenidList = data[i].tokenid.split(",");
            data[i].tokenidOne = data[i].tokenid.split(",")[0];
          }
        }
        this.list3 = [...this.list3, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.syntheticlist-style {
  .ppbox {
    width: 218px;
    padding: 20px 0 4px;
    .ppbox-t1 {
      font-size: 10px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 22px;
      text-align: center;
    }
    .ppbox-b1 {
      font-size: 9px;
      font-weight: 500;
      color: #323232;
      text-align: center;
      flex-wrap: wrap;
      .ppbox-b1-item {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
  .types {
    padding: 20px 20px 0;
    .tlist {
      background: #f4f4f4;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      padding: 1px 2px;
      .titem {
        border-radius: 5px;
        padding: 9px 20px;
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
      .ac {
        background: #ffffff;
      }
      .nos {
        background: #f4f4f4;
      }
    }
  }
  .addpadding {
    padding: 10px 20px 0;
  }
  .list {
    .item {
      padding: 20px 20px 10px;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
      border-bottom: 1px solid #e1e1e1;
      .rows {
        padding: 0 0 10px;
      }
      .rows2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}
</style>